import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import '../styles/Gallery.css';
import MetricsService from '../services/MetricsService';

interface Image {
  id: string;
  number: string;
  displayNumber: string;
  type: 'camara' | 'celular';
  title: string;
  description: string;
  fullImage: string;
  thumbnail: string;
  author: string;
  dateAdded: string;
}

const Gallery: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState<Image[]>([]);
  const [filter, setFilter] = useState<'all' | 'camara' | 'celular'>('all');

  useEffect(() => {
    MetricsService.recordPageVisit();

    fetch('/assets/data/images.json')
      .then(response => response.json())
      .then(data => setImages(data))
      .catch(error => console.error('Error loading images:', error));
  }, []);

  const breakpointColumns = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const handleShare = async (image: Image) => {
    const shareData = {
      title: 'PROM 2024 - Galería de Fotos',
      text: `¡Mira esta foto de ${image.title || 'PROM 2024'}!`,
      url: window.location.origin + image.fullImage
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        // Fallback para navegadores que no soportan Web Share API
        const url = encodeURIComponent(window.location.origin + image.fullImage);
        const text = encodeURIComponent(shareData.text);
        
        const socialLinks = [
          { name: 'WhatsApp', url: `https://wa.me/?text=${text}%20${url}` },
          { name: 'Facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${url}` },
          { name: 'Twitter', url: `https://twitter.com/intent/tweet?text=${text}&url=${url}` }
        ];

        // Crear y mostrar el menú de compartir personalizado
        const shareMenu = document.createElement('div');
        shareMenu.className = 'share-menu';
        socialLinks.forEach(link => {
          const a = document.createElement('a');
          a.href = link.url;
          a.target = '_blank';
          a.rel = 'noopener noreferrer';
          a.textContent = `Compartir en ${link.name}`;
          a.className = 'share-button';
          shareMenu.appendChild(a);
        });

        // Eliminar menú anterior si existe
        const existingMenu = document.querySelector('.share-menu');
        if (existingMenu) {
          existingMenu.remove();
        }

        // Añadir el nuevo menú
        document.body.appendChild(shareMenu);

        // Cerrar menú al hacer clic fuera
        const closeMenu = (e: MouseEvent) => {
          if (!shareMenu.contains(e.target as Node)) {
            shareMenu.remove();
            document.removeEventListener('click', closeMenu);
          }
        };
        setTimeout(() => document.addEventListener('click', closeMenu), 0);
      }
      MetricsService.recordImageMetric(image.id, image.title, 'share');
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const handleDownload = (image: Image) => {
    const link = document.createElement('a');
    link.href = image.fullImage;
    link.download = `${image.title.replace(/\s+/g, '_')}_Prom2024.jpg`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImageClick = (image: Image) => {
    setIsLoading(true);
    setSelectedImage(image);
    MetricsService.recordImageMetric(image.id, image.title, 'view');
  };

  const handleModalClose = () => {
    setSelectedImage(null);
    setIsLoading(true);
  };

  const filteredImages = images.filter(img => filter === 'all' || img.type === filter);

  return (
    <div className="gallery-container">
      <div className="filters">
        <button 
          onClick={() => setFilter('all')} 
          className={filter === 'all' ? 'active' : ''}
        >
          Todas
        </button>
        <button 
          onClick={() => setFilter('camara')} 
          className={filter === 'camara' ? 'active' : ''}
        >
          Cámara
        </button>
        <button 
          onClick={() => setFilter('celular')} 
          className={filter === 'celular' ? 'active' : ''}
        >
          Celular
        </button>
      </div>

      <Masonry
        breakpointCols={breakpointColumns}
        className="gallery-grid"
        columnClassName="gallery-grid_column"
      >
        {filteredImages.map((image) => (
          <div key={image.id} className="gallery-item">
            <img
              src={image.thumbnail}
              alt={image.title}
              onClick={() => handleImageClick(image)}
              onLoad={() => setIsLoading(false)}
            />
          </div>
        ))}
      </Masonry>

      {selectedImage && (
        <div className="modal" onClick={handleModalClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <span className="close" onClick={handleModalClose}>&times;</span>
            {isLoading && (
              <div className="modal-image-placeholder">
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <p>Cargando imagen...</p>
                </div>
              </div>
            )}
            <div className={`modal-image-container ${isLoading ? 'loading' : ''}`}>
              <img
                src={selectedImage.fullImage}
                alt={selectedImage.title}
                style={{ display: isLoading ? 'none' : 'block' }}
                onLoad={() => setIsLoading(false)}
              />
            </div>
            <div className="modal-info">
              <h3>{selectedImage.title}</h3>
              <p>{selectedImage.type === 'camara' ? 'Por Yerson Crespo' : 'Por invitado'}</p>
              <button 
                className="share-btn"
                onClick={() => handleShare(selectedImage)}
              >
                Compartir
              </button>
              <button 
                className="download-btn"
                onClick={() => handleDownload(selectedImage)}
              >
                Descargar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;

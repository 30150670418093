import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Gallery from './components/Gallery';
import bgImage from './assets/images/bg/bg1.jpg';
import bannerImage from './assets/images/banner/PROM2024-108.jpg';

function App() {
  return (
    <Router>
      <div className="App" style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh'
      }}>
        <div className="banner" style={{
          backgroundImage: `url(${bannerImage})`
        }}>
          <div className="banner-content">
            <h1 className="glow-text">
              <span className="prom-year">PROM 2024</span>
              <span className="gallery-subtitle">Galería de Fotos <br/> Yerson Crespo</span>
            </h1>
            <nav>
              <a 
                href="https://drive.google.com/uc?export=download&id=1QpBQlaLf9o6P8FV7K_tUyrwKzLk-FlQs" 
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-download"></i>
                <span>Descargar Todo</span>
              </a>
            </nav>
          </div>
        </div>

        <Routes>
          <Route path="/" element={<Gallery />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

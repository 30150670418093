import { io, Socket } from 'socket.io-client';

export interface ImageMetric {
  image_id: string;
  title: string;
  view_count: number;
  share_count: number;
  download_count: number;
  last_viewed: string;
}

export interface GalleryMetric {
  metric_name: string;
  metric_value: number;
  last_updated: string;
}

class MetricsService {
  private static instance: MetricsService;
  private socket: Socket | null = null;
  private readonly BASE_URL: string;
  private readonly METRICS_API_URL: string;
  private metricsUpdateCallbacks: ((data: any) => void)[] = [];

  private constructor() {
    const isProduction = process.env.NODE_ENV === 'production';
    
    const METRICS_URL = process.env.NODE_ENV === 'production' 
      ? 'https://av-gallery.ysnone.com:4024' 
      : process.env.REACT_APP_METRICS_URL || 'http://localhost:4024';

    this.BASE_URL = METRICS_URL;
    this.METRICS_API_URL = `${this.BASE_URL}/api/metrics`;
    
    console.log('Metrics Base URL:', this.BASE_URL);
    console.log('Metrics API URL:', this.METRICS_API_URL);
    
    this.initializeSocket();
  }

  private initializeSocket() {
    try {
      this.socket = io(this.BASE_URL, {
        transports: ['websocket', 'polling'],
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 1000,
        timeout: 20000,
        forceNew: true,
        autoConnect: true,
        withCredentials: false,
        extraHeaders: {
          'Access-Control-Allow-Origin': '*'
        }
      });

      this.socket.on('connect', () => {
        console.log(' Conectado al servidor de métricas');
      });

      this.socket.on('metrics_update', (data: any) => {
        console.log(' Métricas recibidas:', data);
        this.metricsUpdateCallbacks.forEach(callback => callback(data));
      });

      this.socket.on('connect_error', (error) => {
        console.error(' Error de conexión Socket.IO:', error);
      });

      this.socket.on('disconnect', (reason) => {
        console.log(' Desconectado del servidor de métricas:', reason);
        this.socket?.connect();
      });
    } catch (error) {
      console.error(' Error inicializando socket:', error);
    }
  }

  public static getInstance(): MetricsService {
    if (!MetricsService.instance) {
      MetricsService.instance = new MetricsService();
    }
    return MetricsService.instance;
  }

  // Métodos estáticos para compatibilidad
  public static recordPageVisit(): void {
    const instance = MetricsService.getInstance();
    instance.recordVisit();
  }

  public static recordImageMetric(
    imageId: string, 
    title: string, 
    metricType: 'view' | 'share' | 'download'
  ): void {
    const instance = MetricsService.getInstance();
    switch(metricType) {
      case 'view':
        instance.recordImageView(imageId, title);
        break;
      case 'share':
        instance.recordImageShare(imageId, title);
        break;
      case 'download':
        instance.recordImageDownload(imageId, title);
        break;
    }
  }

  public static async getImageMetrics(): Promise<ImageMetric[]> {
    const instance = MetricsService.getInstance();
    return instance.getImageMetrics();
  }

  public static async getGalleryMetrics(): Promise<GalleryMetric[]> {
    const instance = MetricsService.getInstance();
    return instance.getGalleryMetrics();
  }

  public onMetricsUpdate(callback: (data: any) => void) {
    this.metricsUpdateCallbacks.push(callback);
  }

  public removeMetricsUpdateCallback(callback: (data: any) => void) {
    this.metricsUpdateCallbacks = this.metricsUpdateCallbacks.filter(cb => cb !== callback);
  }

  public async recordImageView(imageId: string, title: string): Promise<void> {
    try {
      await this.sendMetric('view', imageId, title);
    } catch (error) {
      console.error('Error registrando vista de imagen:', error);
    }
  }

  public async recordImageShare(imageId: string, title: string): Promise<void> {
    try {
      await this.sendMetric('share', imageId, title);
    } catch (error) {
      console.error('Error registrando compartida de imagen:', error);
    }
  }

  public async recordImageDownload(imageId: string, title: string): Promise<void> {
    try {
      await this.sendMetric('download', imageId, title);
    } catch (error) {
      console.error('Error registrando descarga de imagen:', error);
    }
  }

  private async sendMetric(
    metricType: 'view' | 'share' | 'download', 
    imageId: string, 
    title: string
  ): Promise<void> {
    try {
      const response = await fetch(`${this.METRICS_API_URL}/image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          imageId,
          title,
          metricType
        })
      });

      if (!response.ok) {
        console.error(`Error enviando métrica ${metricType}:`, response.statusText);
      }
    } catch (error) {
      console.error(`Error de red enviando métrica ${metricType}:`, error);
    }
  }

  public async recordVisit(): Promise<void> {
    try {
      await fetch(`${this.METRICS_API_URL}/visit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });
    } catch (error) {
      console.error('Error al registrar visita:', error);
    }
  }

  public async getImageMetrics(): Promise<ImageMetric[]> {
    try {
      const response = await fetch(`${this.METRICS_API_URL}/images`);
      return await response.json();
    } catch (error) {
      console.error('Error al obtener métricas de imágenes:', error);
      return [];
    }
  }

  public async getGalleryMetrics(): Promise<GalleryMetric[]> {
    try {
      const response = await fetch(`${this.METRICS_API_URL}/gallery`);
      return await response.json();
    } catch (error) {
      console.error('Error al obtener métricas de galería:', error);
      return [];
    }
  }
}

export default MetricsService;
